import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { LocalizedContent } from 'src/app/commons/model/localized-content';
import { getCurrentContent, AppState } from 'src/app/store/reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as ContentActions from '../../../../store/actions/content.actions';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-cover',
  templateUrl: './cover.component.html',
  styleUrls: ['./cover.component.scss'],
})
export class CoverComponent implements OnInit {

  localizedContent$: Observable<LocalizedContent> = this.store.select(getCurrentContent);
  bgPath: string;

  @ViewChild('header') headerImage: ElementRef;

  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
    private renderer: Renderer2,
    private store: Store<AppState>,
    private router: Router,
    private loaderService: NgxUiLoaderService
  ) { }

  ngOnInit() {

    let coords = this.navParams.get('coords');

    this.renderer.setStyle(this.headerImage.nativeElement, 'transform', `translate3d(${coords.x}px, ${coords.y - 56}px, 0) scale3d(0.4, 0.4, 1)`);
    this.renderer.setStyle(this.headerImage.nativeElement, 'transition', '.5s ease-in-out');

    setTimeout(() => {
      this.renderer.removeStyle(this.headerImage.nativeElement, 'transform');
    }, 30);

  }

  openSection(section: LocalizedContent) {
    this.loaderService.start();
    if (section.level == 1) this.store.dispatch(new ContentActions.SetCurrentGUID({ guid: null }));

    this.store.dispatch(new ContentActions.SetCurrentSectionGUID({sectionGUID: section.guid}));

    this.store.dispatch(new ContentActions.SetCurrentSection({ section: section.name, sectionName: section.localizedName, sectionID: section.id }));
    this.localizedContent$.pipe(take(1)).subscribe(localizedContent => {
      this.router.navigateByUrl(`/tutorials/${localizedContent.contentID}/section/${section.id}/lang/${localizedContent.language}/guid/`);
      this.modalCtrl.dismiss();
    })
  }

  close() {
    this.modalCtrl.dismiss();
  }

}
