import { Component, OnInit, Input, ElementRef, Renderer2 } from '@angular/core';
import { DomController, Platform, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { LocalizedContent } from 'src/app/commons/model/localized-content';
import { arrayFlatten } from 'src/app/helpers/array.helper';
import { AppState, getCurrentContent } from 'src/app/store/reducers';
import { Store } from '@ngrx/store';
import * as ContentActions from '../../../../store/actions/content.actions';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-table-of-contents',
  templateUrl: './table-of-contents.component.html',
  styleUrls: ['./table-of-contents.component.scss'],
})
export class TableOfContentsComponent implements OnInit {

  localizedContent$: Observable<LocalizedContent> =  this.store.select(getCurrentContent);

  // TREE VIEW 
  treeControl = new NestedTreeControl<LocalizedContent>(node => node.allChildren);
  dataSource = new MatTreeNestedDataSource<LocalizedContent>();

  // DRAWER LOGIC
  @Input("options") options: any;
  @Input("draggable") draggable: boolean;
  handleHeight: number;
  bounceBack: boolean = true;
  thresholdTop: number = 100;
  thresholdBottom: number = 100;
  isOpen: boolean = false;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private domCtrl: DomController,
    private platform: Platform,
    private router: Router,
    private modalCtrl: ModalController,
    private store: Store<AppState>
  ) { }

  ngOnInit() {
    this.localizedContent$.pipe(take(1)).subscribe((localizedContent => this.dataSource.data = localizedContent.allChildren.filter((child)=>child.displayName!='12-Sect_Empty_V6B')));
  }

  ngAfterViewInit() {
    this.handleHeight = 44; // iOS height
    if (this.draggable) {
      this.renderer.setStyle(this.element.nativeElement, "transform", `translateY(${this.platform.height() - this.handleHeight}px)`);
    } else {
      this.renderer.setStyle(this.element.nativeElement, "transform", `translateY(${this.platform.height()}px`);
    }
  }

  handlePan(ev) {
    
    if (this.draggable) {

      let newTop = ev.center.y;

      let bounceToBottom = false;
      let bounceToTop = false;
  
      if (this.bounceBack && ev.isFinal) {
        let topDiff = newTop - this.thresholdTop;
        let bottomDiff = this.platform.height() + this.thresholdBottom - newTop;
  
        topDiff >= bottomDiff ? (bounceToBottom = true) : (bounceToTop = true);
      }
  
      if ((newTop < this.thresholdTop && ev.additionalEvent === "panup") || bounceToTop) {
  
        this.domCtrl.write(() => {
          this.renderer.setStyle(this.element.nativeElement, "transition", "transform 0.5s");
          this.renderer.setStyle(this.element.nativeElement, "transform", `translateY(100px)`);
  
          this.isOpen = true;
        });
  
      } else if ((this.platform.height() - newTop < this.thresholdBottom && ev.additionalEvent === "pandown") || bounceToBottom) {
  
        this.domCtrl.write(() => {
          this.renderer.setStyle(this.element.nativeElement, "transition", "transform 0.5s");
          this.renderer.setStyle(this.element.nativeElement, "transform", `translateY(${this.platform.height() - this.handleHeight}px)`);
  
          this.isOpen = false;
        });
  
      } else {
  
        this.renderer.setStyle(this.element.nativeElement, "transition", "none");
  
        if (newTop > 0 && newTop < this.platform.height() - this.handleHeight) {
          if (ev.additionalEvent === "panup" || ev.additionalEvent === "pandown") {
            this.domCtrl.write(() => {
              this.renderer.setStyle(this.element.nativeElement, "transform", `translateY(${newTop}px)`);
              this.isOpen = false;
            });
          }
        }
  
      }

    }
  }

  toggleDrawer() {
    if (this.isOpen) {
      this.renderer.setStyle(this.element.nativeElement, "transition", "transform 0.5s");

      if (this.draggable) {
        this.renderer.setStyle(this.element.nativeElement, "transform", `translateY(${this.platform.height() - this.handleHeight}px)`);
      } else {
        this.renderer.setStyle(this.element.nativeElement, "transform", `translateY(${this.platform.height()}px)`);
      }

      this.isOpen = false;
    } else {
      this.renderer.setStyle(this.element.nativeElement, "transition", "transform 0.5s");
      this.renderer.setStyle(this.element.nativeElement, "transform", `translateY(100px)`);

      this.isOpen = true;
    }
  }
  
  goToChapter(node: LocalizedContent) {

    this.localizedContent$.pipe(take(1)).subscribe(localizedContent => {
      
      let flattenTree = [];
      let section = node;

      arrayFlatten(localizedContent, "allChildren", flattenTree);
      while (section.level > 1) {
        flattenTree.forEach(l => {
          if (l.id == section.parentID) {
            section = l;
          }
        })
      }
  
      this.store.dispatch(new ContentActions.SetCurrentGUID({ guid: node.guid }));
      this.store.dispatch(new ContentActions.SetCurrentSection({ section: section.name, sectionName: section.localizedName, sectionID: section.id }));
      this.toggleDrawer();
      this.localizedContent$.pipe(take(1)).subscribe(localizedContent => {
        this.modalCtrl.dismiss();
        this.router.navigateByUrl(`/tutorials/${localizedContent.contentID}/section/${section.id}/lang/${localizedContent.language}/guid/${node.guid}`);
      })      
    })

  }
  
  hasChild = (_: number, node: LocalizedContent) => !!node.allChildren && node.allChildren.length > 0;

}
