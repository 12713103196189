import { Injectable } from '@angular/core';
import { AttachmentTranslation, LaravelContentService } from '../backend/laravel-content.service';
import { LocalizedContent } from '../../model/localized-content';
import { map, switchMap, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AlertService } from '../alert.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Store } from "@ngrx/store";
import { AppState, getCurrentContent } from 'src/app/store/reducers';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor(
    private laravelContentService: LaravelContentService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private router: Router,
    private store: Store<AppState>
  ) { }

  searchForQuery(query: string): Observable<LocalizedContent[]> {
    return this.laravelContentService.search(query).pipe(map(contents => contents.map(c => new LocalizedContent(c))));
  }

  getContents(): Observable<LocalizedContent[]> {
    //sbianchini commento l'ordinamento preferiti poichè richiesto quello lato backend
    /*return this.laravelContentService.index().pipe(map(contents => contents.sort((a,b) => {
      let aNumber = a.content.is_favorites_of_users_count ? 1 : 0;
      let bNumber = b.content.is_favorites_of_users_count ? 1 : 0;
      return bNumber - aNumber;
    }).map(c => new LocalizedContent(c))));*/
    return this.laravelContentService.index().pipe(map(contents => contents.map(c => new LocalizedContent(c))));
  }

  getFavoriteContents(): Observable<LocalizedContent[]> {
    return this.laravelContentService.favorites().pipe(map(contents => contents.map(c => new LocalizedContent(c))));
  }

  getTreeView(contentID: number, language?: string) {
    return this.laravelContentService.treeView(contentID, language).pipe(map(content => new LocalizedContent(content)));
  }

  getChapterHTML(localizedContentID: number, sectionID: string, language?: string): Observable<string> {
    return this.laravelContentService.getSection(localizedContentID, sectionID, language);
  }

  getSectionIDFromGUIDAndLanguage(localizedContentID: number, sectionGUID: string, language?: string): Observable<string> {
    return this.laravelContentService.getSectionIDFromGUIDAndLanguage(localizedContentID, sectionGUID, language);
  }

  addToFavorites(localizedContentID: number): Observable<LocalizedContent> {
    return this.laravelContentService.addToFavs(localizedContentID).pipe(map(favResponse => new LocalizedContent(favResponse.localizedContentDTO)));
  }

  removeFromFavorites(localizedContentID: number): Observable<LocalizedContent> {
    return this.laravelContentService.removeFromFavs(localizedContentID).pipe(map(favResponse => new LocalizedContent(favResponse.localizedContentDTO)));    
  }

  downloadPDF(pdfURL: string, pdfSize: string) {
    this.alertService.showConfirm(
      this.translateService.instant(`NOTIFY.alertTitle`), 
      this.translateService.instant('NOTIFY.downloadMsg') + ` ${pdfSize}`, 
      this.translateService.instant('ACTIONS.yes'), 
    ).pipe(take(1)).subscribe((response) => {
      if (response) {
        this.router.navigate([]).then(result => {  window.open(pdfURL, '_blank'); });
      }
    })
  }

  getAttachmentTranslations(attachmentUUID: string) : Observable<AttachmentTranslation[]> {
    return this.store
    .select(getCurrentContent)
    .pipe(
      map(
        (locContent) => locContent.language),
        switchMap(language => this.laravelContentService.attachmentTranslations(attachmentUUID, language))
      );
  }

}
