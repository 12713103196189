import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LanguageService } from '../language.service';
import { LocalizedContentDTO } from '../../model/localized-content';

export interface FavResponse {
  message: string;
  status: number;
  localizedContentDTO: LocalizedContentDTO;
}

@Injectable({
  providedIn: 'root'
})
export class LaravelContentService {

  constructor(
    private httpClient: HttpClient,
    private languageService: LanguageService
  ) { }

  private get ROUTES() {
    return {
      list: environment["baseURL"] + "/api/contents",
      search: environment["baseURL"] + "/api/search",
      section: environment["baseURL"] + "/api/contents",
      treeView: environment["baseURL"] + "/api/contents",
      favorites: environment["baseURL"] + "/api/favorites",
      addToFavs: environment["baseURL"] + "/api/favorites",
      removeFromFavs: environment["baseURL"] + "/api/favorites",
      attachmentTranslations: environment["baseURL"] + "/api/attachments",
      getSectionIDFromGUIDAndLanguage: environment["baseURL"] + "/api/contents/get-id-from-guid"
    };
  }

  index(): Observable<LocalizedContentDTO[]> {
    return this.httpClient.get<LocalizedContentDTO[]>(`${this.ROUTES.list}/${this.languageService.selectedLanguage.backendValue}`);
  }

  search(query: string): Observable<LocalizedContentDTO[]> {
    let queryParams: HttpParams = new HttpParams().set('query', query);
    return this.httpClient.get<LocalizedContentDTO[]>(`${this.ROUTES.search}`, { params: queryParams });
  }

  treeView(contentID: number, language?: string): Observable<LocalizedContentDTO> {
    return this.httpClient.get<LocalizedContentDTO>(
      `${this.ROUTES.treeView}/${language ? language : this.languageService.selectedLanguage.backendValue}/${contentID}`
    );
  }

  getSection(localizedContentID: number, sectionID: string, language?: string): Observable<string> {
    return this.httpClient.get(
      `${this.ROUTES.list}/${language ? language : this.languageService.selectedLanguage.backendValue}/${localizedContentID}/section/${sectionID}`,
      { responseType: 'text' }
    );
  } 

  getSectionIDFromGUIDAndLanguage(localizedContentID: number, sectionGUID: string, language?: string): Observable<string> {
    return this.httpClient.get(
      `${this.ROUTES.getSectionIDFromGUIDAndLanguage}/${language ? language : this.languageService.selectedLanguage.backendValue}/${localizedContentID}/${sectionGUID}`,
      { responseType: 'text' }
    );
  } 

  favorites(): Observable<LocalizedContentDTO[]> {
    return this.httpClient.get<LocalizedContentDTO[]>(`${this.ROUTES.favorites}`);  
  }

  addToFavs(localizedContentID: number): Observable<FavResponse> {
    return this.httpClient.post<FavResponse>(`${this.ROUTES.addToFavs}/${localizedContentID}`, {});
  }

  removeFromFavs(localizedContentID: number): Observable<FavResponse> {
    return this.httpClient.delete<FavResponse>(`${this.ROUTES.removeFromFavs}/${localizedContentID}`);
  }

  attachmentTranslations(attachmentUUID: string, language: string): Observable<AttachmentTranslation[]> {
    return this.httpClient.get<AttachmentTranslation[]>(`${this.ROUTES.attachmentTranslations}/${attachmentUUID}/translations/${language}`);
  }

}

export interface AttachmentTranslation {
  label: string;
  code: string;
}
