import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Feedback } from "../../model/feedback";
import { LaravelFeedbackService } from "../backend/laravel-feedback.service";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class FeedbackService {
  constructor(private laravelFeedbackService: LaravelFeedbackService) {}

  getFeedbacks(): Observable<Feedback[]> {
    return this.laravelFeedbackService
      .index()
      .pipe(map((feedbacks) => feedbacks.map((f) => new Feedback(f))));
  }

  getFeedbacksByContent(
    contentID: number,
    sectionID: string,
    language: string
  ): Observable<Feedback[]> {
    return this.laravelFeedbackService
      .listByContent(contentID, sectionID, language)
      .pipe(map((feedbacks) => feedbacks.map((f) => new Feedback(f))));
  }

  postFeedback(
    feedback: string,
    name: string,
    surname: string,
    dealer: string,
    city: string,
    sectionID?: string,
    guid?: string,
    contentID?: number
  ): Observable<Feedback> {
    return this.laravelFeedbackService
      .create(
        sectionID,
        feedback,
        name,
        surname,
        dealer,
        city,
        guid,
        contentID,
        sectionID
      )
      .pipe(map((f) => new Feedback(f)));
  }

  editFeedback(feedbackID: number, text: string): Observable<Feedback> {
    return this.laravelFeedbackService
      .edit(feedbackID, text)
      .pipe(map((f) => new Feedback(f)));
  }

  deleteFeedback(feedbackID: number): Observable<Feedback> {
    return this.laravelFeedbackService
      .delete(feedbackID)
      .pipe(map((f) => new Feedback(f)));
  }
}
