
import * as fromContent from "../actions/content.actions";
import { LocalizedContent } from 'src/app/commons/model/localized-content';

export interface ContentState {
  currentContent: LocalizedContent,
  currentSection: string,
  currentSectioName: string,
  currentGUID: string,
  currentSectionID: number,
  currentSectionGUID: string
}

export const initialState: ContentState = {
  currentContent: null,
  currentSection: null,
  currentSectioName: null,
  currentGUID: null,
  currentSectionID: null,
  currentSectionGUID: null
};

export function reducer(
  state = initialState,
  action: fromContent.ActionsUnion
): ContentState {
  switch (action.type) {

    case fromContent.ActionTypes.SetCurrentContent: {
      return {
        ...state,
        currentContent: action.payload.localizedContent
      };
    }

    case fromContent.ActionTypes.SetCurrentSection: {
      return {
        ...state,
        currentSection: action.payload.section,
        currentSectioName: action.payload.sectionName,
        currentSectionID: action.payload.sectionID
      };
    }

    case fromContent.ActionTypes.SetCurrentGUID: {
      return {
        ...state,
        currentGUID: action.payload.guid
      };
    }

    case fromContent.ActionTypes.SetCurrentSectionGUID: {
      return {
        ...state,
        currentSectionGUID: action.payload.sectionGUID
      };
    }

    default: {
      return state;
    }
  }
}

export const currentContent = (state: ContentState) => state.currentContent;
